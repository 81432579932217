<template>
    <div>
        <div class="header">
            <span class="header_txt">勋章详情</span>
        </div>
        <div class="header_box">
            <div class="left">
                <button class="header_btn" @click="returnUrl">返回</button>
                <button class="header_btn" @click="update">更新</button>
            </div>
            <div class="right"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row, index }" slot="type">
                    <span class="infoModal_text" @click="receive">查看详情</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 领取人查看弹框 -->
        <Modal v-model="receiveModal" title="领取用户" width="50%">
            <div class="detailsModal_content">
                <Table :columns="receiveTable" :data="receiveDataTable" border :no-data-text="noDataText">
                    <template slot-scope="{ row, index }" slot="picture">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img src="@/assets/logo.jpg" alt="" style="width: 50px;">
                        </div>
                    </template>
                    <template slot-scope="{ row, index }" slot="type">
                        <span class="infoModal_text">操作</span>
                    </template>
                </Table>
            </div>
            <div class="footer">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                //table
                liveTable: [
                    {title: '数量',key: 'num',align:'center'},
                    {title: '已领取人数',key: 'personNum',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [
                    { num: '41', personNum: '0'},
                    { num: '14', personNum: '0'}
                ],
                //page
                total: 10,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                totalModal: 10,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                },
                receiveModal:false,
                receiveTable: [
                    {title: '头像',slot: 'picture',align:'center'},
                    {title: '昵称',key: 'name',align:'center'},
                    {title: '手机号',key: 'phone',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                receiveDataTable: [
                    // {name: '用户8888',phone: '13756894125'},
                ],
                noDataText:'暂无数据',
            }
        },
        methods: {
            pageChange(index) {
                this.storePrams.page = index;
            },
            receive(){
                this.receiveModal = true
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            // 返回
            returnUrl(){
                this.$router.back()
            },
            // 更新
            update(){
                console.log('更新-调列表接口')
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin: 10px 0;
}
.header_btn{
    padding: 8px 20px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
::v-deep .ivu-input.ivu-input-default{
    height: 40px;
}
</style>